import React from "react";
import { DefaultLayout } from "../../organisms/DefaultLayout";
import * as styles from "../../styles/careers/carrer-mid-info.module.scss";

export default () => (
  <DefaultLayout>
    <div
      className={styles.cover}
      style={{ backgroundImage: "url('https://placehold.jp/1024x400.jpg')" }}
    ></div>
    <div className={styles.wrapper}>
      <div className={styles.midashi}>
        <h1>Employment info</h1>
        <p>採用情報</p>
      </div>

      <table className={styles.info}>
        <tr>
          <th>システム事業部</th>
          <td>システム開発・保守</td>
        </tr>
        <tr>
          <th></th>
          <td>
            各事業部の要望に沿ったシステム開発、保守。一般販売向けシステム開発、保守。
          </td>
        </tr>

        <tr>
          <th>採用事業部</th>
          <td>システム事業部</td>
        </tr>
        <tr>
          <th>事業部概要</th>
          <td>
            主にシステムの開発を行っている部門です。一つのシステムを開発して保守やアップデートをすれば良いということではなく、各事業部の要望に沿ったシステム開発が必要となります。その要望をもとに作りフィードバックを受けてさらにより良いものにしていくことで、使いやすいシステムを実現しています。
            また社内で培ったシステムは同一業界の役に立つことができるのではないかと考え一般販売を行うことで、お客様から違った目線の要望事項も取り入れ業界No.1のシステムとなるように改善しています。
          </td>
        </tr>
        <tr>
          <th>職務内容</th>
          <td>
            各事業部の要望に沿ったシステム開発、保守。一般販売向けシステム開発、保守。
            一般販売向けシステム開発、保守。
          </td>
        </tr>
        <tr>
          <th>事業部の将来目標</th>
          <td>
            部署のメンバー全員が面倒くさいことが嫌いで効率を重視した生き方をしています。コンピューターができることは人間があまり干渉しないでクリエイティブな仕事に集中できるような世界を作りたいですね。開発したシステムを使ってもらうことで、売上であったり従業員であったり大幅な向上に貢献できるようなそういった担い手になりたいです。あって当然なくても困る存在を目指して開発を続けていきます。
          </td>
        </tr>
        <tr>
          <th>部の雰囲気</th>
          <td>
            仕事柄全員が黙々とPCに向き合っているので、ストレートに会話することはあまりありません。だからといってギスギスしているわけでは決してありませんよ。それぞれが集中して仕事をする必要がありますし。現在の部署メンバーは全員が以前からの知り合いだったこともありお互いを理解しあえているかな。と思っています。自分もこの環境を居心地よく感じていて日々集中して作業に取り掛かることができています。
          </td>
        </tr>

        <tr>
          <th>求める人物・スキル</th>
          <td>
            良いものを作るということを大前提に仕事ができる方を求めています。仕様書を作成した段階では見落としていたことや仕様書よりも開発中に良いアイデアの指摘が入ることは頻繁に起こりえます。そういう時にその点を柔軟に改善できる方を何よりも求めています。すべての事業部をカバーする上で一つの言語ではカバーしきれないこともあり、新言語の習得にも前向きな方が望ましいですね。
            サーバサイドや内部ロジックの案件を多くこなしてきたメンバーが多く、UI/UXは不得意としているので、得意な方がご応募してくださると力強いです。
          </td>
        </tr>

        <tr>
          <th>応募資格(必須)</th>
          <td>・○○に関連する業務経験3年以上 ・○○の知識</td>
        </tr>

        <tr>
          <th>応募資格(歓迎)</th>
          <td>・UI/UXに関連する業務経験</td>
        </tr>
        <tr>
          <th>雇用形態</th>
          <td>
            正社員
            <p className={styles.cf}>※契約期間の定めなし</p>
            <p className={styles.cf}>※試用期間あり（3ヵ月）</p>
          </td>
        </tr>
        <tr>
          <th>給与</th>
          <td>
            <b>総合職（総合コース・エンジニアコース）</b>
            <p> 高専卒（本科）：月給233,000円～</p>
            <p> 大　卒・高専卒（専攻科）：月給243,000円～</p>
            <p>修士了：月給264,200円〜</p>
            <p> 博士了：月給274,800円～</p>
            <p className={styles.cf}>
              ※卓越した能力、高度な技術や実績をお持ちの方で、それらを入社後の実業務において発揮できると認められる場合は、上記の給与に関わらず個別設定することがあります
            </p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>月給205,000円</p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>月給205,000円</p>
            <br />
            <p>
              <b>全職種2021年度見込み</b>
            </p>
            <p className={styles.cf}>※自己成長支援金（1万円）を含む</p>
          </td>
        </tr>
        <tr>
          <th>勤務予定地</th>
          <td>
            <b>2総合職（総合コース・エンジニアコース）</b>
            <p>
              <b>大阪本社・東京本社・名古屋市支社・福岡支社・札幌支社</b>
            </p>
            <p className={styles.cf}>※全国・海外転勤あり</p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>
              <b>大阪本社・東京本社・名古屋市支社・福岡支社</b>
            </p>
            <p className={styles.cf}>※全国・海外転勤なし</p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>
              <b>大阪本社・東京本社・名古屋市支社・福岡支社</b>
            </p>
            <p className={styles.cf}>※全国・海外転勤なし</p>
            <br />
          </td>
        </tr>
        <tr>
          <th>給与</th>
          <td>
            <b>総合職（総合コース・エンジニアコース）</b>
            <p> 高専卒（本科）：月給233,000円～</p>
            <p> 大　卒・高専卒（専攻科）：月給243,000円～</p>
            <p>修士了：月給264,200円〜</p>
            <p> 博士了：月給274,800円～</p>
            <p className={styles.cf}>
              ※卓越した能力、高度な技術や実績をお持ちの方で、それらを入社後の実業務において発揮できると認められる場合は、上記の給与に関わらず個別設定することがあります
            </p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>月給205,000円</p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>月給205,000円</p>
            <br />
            <p>
              <b>全職種2021年度見込み</b>
            </p>
            <p className={styles.cf}>※自己成長支援金（1万円）を含む</p>
          </td>
        </tr>
        <tr>
          <th>勤務予定地</th>
          <td>
            <b>大阪本社・東京本社・名古屋市支社・福岡支社・札幌支社</b>
            <p className={styles.cf}>※全国・海外転勤あり</p>
          </td>
        </tr>
        <tr>
          <th>昇給</th>
          <td>年1回（原則）</td>
        </tr>

        <tr>
          <th>賞与</th>
          <td>年2回 ※年間標準賞与5カ月＋特別加算賞与（業績により支給）</td>
        </tr>
        <tr>
          <th>勤務時間</th>
          <td>
            原則午前9時～午後5時45分（実働7時間45分＋休憩時間
            原則12時～13時までの1時間）
            <p className={styles.cf}>
              ※スーパーフレックスタイム制（コアタイム無し）
              <br />
              ※一部、例外部署もあり（所定労働勤務・シフト勤務・交替制勤務など）
              <br />
              ※時間外労働あり
            </p>
          </td>
        </tr>

        <tr>
          <th>休日休暇</th>
          <td>
            週休2日制（年間休日日数
            126日/2019年度実績）、年次有給休暇、慶弔休暇、リフレッシュ休暇、
            産前産後休暇、育児休業、配偶者出産休暇、キッズ休暇、介護休暇、介護休業、看護休暇、ボランティア活動休暇、
            ドナー休暇、ハンディキャップ休暇（障がいのための通院休暇） など
            <p className={styles.cf}>
              ※休日休暇について、詳しくはこちらをご覧ください。
            </p>
          </td>
        </tr>

        <tr>
          <th>諸手当</th>
          <td>
            割増手当（時間外勤務手当、深夜勤務手当、休日勤務手当）、通勤手当、新卒住宅補助
            など
          </td>
        </tr>

        <tr>
          <th>福利厚生</th>
          <td>
            財形貯蓄、持株会、慶弔見舞金、確定拠出年金制度、育児支援制度、福利厚生サービス会社（ベネフィット・ワン）提携
            など
            <p className={styles.cf}>
              ※福利厚生について、詳しくはこちらをご覧ください。
            </p>
          </td>
        </tr>
        <tr>
          <th>研修制度</th>
          <td>
            能力開発体系に基づく研修（新入社員研修、3年目社員ステップアップ研修、新任管理職層研修、各種スキル研修、英語・統計・テクノロジー研修）、OJT
            など
            <p className={styles.cf}>
              ※キャリア（研修制度）について、詳しくはこちらをご覧ください。
            </p>
          </td>
        </tr>
        <tr>
          <th>保険</th>
          <td>健康保険、厚生年金、雇用保険、労災保険完備</td>
        </tr>
        <tr>
          <th>その他</th>
          <td>
            各キャリア開発支援制度（新規ポジションの社内公募、資格取得支援
            など）、パソコン・携帯電話貸与（弊社規定による）、モバイル特別販売、永年勤続表彰など
          </td>
        </tr>
        <tr>
          <th>応募者へのメッセージ</th>
          <td>
            各事業部がその業界のNo１を目指すうえでシステム導入は必要不可欠です。すべてを内製する予定はありませんが、Saasなど利用する上でそれを補完するシステムの開発も必要になります。仕事の内容は多種に渡っていますが、決して一人に負荷集中することや負荷が高まるようなことはありません。
            共に上を目指していける方のご応募をお待ちしております。
            一緒に働ける日を楽しみにしています。
          </td>
        </tr>
      </table>

      <div className={styles.iconbox}>
        <span className={styles.icon}>上記内容を確認のうえエントリーする</span>
      </div>
    </div>
  </DefaultLayout>
);
